import React, { memo } from 'react'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { BsFillPlusCircleFill, BsCheckCircle } from 'react-icons/bs'
import styled from '@emotion/styled'
import { flex } from '~/modules/AppLayout/FlexGridCss'

export const SetSymbolButton = styled.div`
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #5069ad;
  cursor: pointer;
  user-select: none;
  &:hover {
    transition: 0.3s;
    background-color: #6788e0;
  }
  &:active {
    background-color: #5597fa;
    transition: 0.3s;
  }
`
/** 新增商品至自選股按鈕 */
export const AddSymbolButton = memo<
  ReactProps<{
    groupName: string
    symbolNumber: Socket.SymbolString
    listSize: number
  }>
>(function AddButton_(props) {
  const { acts } = useSymbolWatchList(props.groupName)
  return (
    <SetSymbolButton
      theme={true}
      onClick={() => acts.addSymbol(props.symbolNumber, props.listSize)}
    >
      <BsFillPlusCircleFill />
      新增 {<SymbolName symbol={props.symbolNumber} />} 至自選股
    </SetSymbolButton>
  )
})

export const AddSymbolButtonSmall = memo<
  ReactProps<{
    groupName: string
    symbolNumber: Socket.SymbolString
    listSize: number
    /** 加入自選股___補充描述 ex: 加入自選股群組3 */
    describeString?: string
  }>
>(function AddButtonSmall_(props) {
  const { state, acts } = useSymbolWatchList(props.groupName)
  const watchList = state.symbolListArray
  const checkSymbolInWatchList = watchList?.includes(props.symbolNumber)
  return (
    <SetSymbolButton
      theme={true}
      onClick={() => acts.addSymbol(props.symbolNumber, props.listSize)}
    >
      {!checkSymbolInWatchList ? (
        <>
          <BsFillPlusCircleFill /> &nbsp; 加入自選股{props.describeString}
        </>
      ) : (
        <>
          <BsCheckCircle /> &nbsp; 已加入
        </>
      )}
    </SetSymbolButton>
  )
})
