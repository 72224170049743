import { css } from '@emotion/react'
import { MenuItem, menuClasses, Select, selectClasses } from '@mui/material'
import { memo } from 'react'
import { createChartStore } from '~/modules/SDK/Chart2/createChartStore'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
/**
 * TVChart 伺服器選單
 *
 * - 本組件取代 <_ChartServerSwitch />。
 * - 差別1. 不再傳入 styles 的 props。
 * - 差別2. 傳入 useChart 與 createChartStore 解藕（方便未來獨立寫測試）。
 * - CSS 客製化模式，採用 mui CSS 覆蓋模式，將 export 相關的 CSS classes keys。
 *
 * @example
 *   // 基本使用方式
 *
 *   return (
 *     <Fragment>
 *       <Global
 *         styles={css`
 *           #__next {
 *             .${ChartServerSelectClasses.muiSelect.root} {
 *               // ...
 *             }
 *
 *             .${ChartServerSelectClasses.muiSelect.select} {
 *               color: #ff1100;
 *               background-color: #76e77f;
 *             }
 *           }
 *         `}
 *       ></Global>
 *
 *       <ChartServerSelect useChart={useChart1} />
 *       <ChartServerSelect useChart={useChart2} />
 *     </Fragment>
 *   )
 */
export const ChartServerSelect = memo<
  ReactProps<{
    id?: string
    useChart: ReturnType<typeof createChartStore>
    onChange?(selectedServer: ChartTypes.ServerConfig): void
  }>
>(function ChartServerSelect(props) {
  const currentServer = props.useChart(state => state.serverSelected)
  const servers = props.useChart.getState().servers

  return (
    <span
      id={props.id}
      className={`${props.className} ${ChartServerSelectClasses.root}`}
      //若無設定寬高 則Select-ui會跑版(原生樣式)
      css={css`
        #__next & {
          ${flex.h.default};
          box-sizing: border-box;
          padding: 4px;
        }
      `}
    >
      <Select
        size='small'
        value={currentServer?.historyUrl || ''}
        renderValue={value => {
          return currentServer.label
        }}
        onChange={event => {
          const url = event.target.value
          const server = servers.find(_server => _server.historyUrl === url)

          if (server) {
            props.useChart.setState({ serverSelected: server })
            props.onChange?.(server)
          }
        }}
        //若物件設定寬高為100% 則會依賴外層寬高
        css={css`
          width: 100%;
          height: 100%;
        `}
        className={`${ChartServerSelectClasses.button}`}
      >
        {servers.map((server, index) => {
          return (
            <MenuItem
              key={index}
              value={server.historyUrl}
            >
              {server.label}
            </MenuItem>
          )
        })}
      </Select>
    </span>
  )
})

export const ChartServerSelectClasses = {
  root: `${ChartServerSelect.name}-root`,
  button: `${ChartServerSelect.name}-button`,
  muiSelect: selectClasses,
  muiMenuItem: menuClasses,
}
